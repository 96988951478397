<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        :label="label"
        :placeholder="placeholder"
        :clearable="clearable"
        class="c-input-xs"
        v-bind="attrs"
        v-on="on"
        readonly
        dense
        outlined
        hide-details
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu"
      v-model="time"
      full-width
      format="24hr"
      @click:minute="$refs.menu.save(time)"
    ></v-time-picker>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  name: "TimePicker",
  props: {
    label: {
      type: String,
      default: () => null,
    },
    placeholder: {
      type: String,
      default: () => null,
    },
    clearable: {
      type: Boolean,
      default: () => true,
    },
    defaultValue: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      time: null,
      menu: false,
    };
  },
  watch: {
    time() {
      this.onTimeChange();
    },
  },
  methods: {
    onTimeChange() {
      const today = moment().format("YYYY-MM-DD");
      const time = moment(`${today} ${this.time}:00`)
        .startOf("minute")
        .format("HH:mm:ss");
      this.$emit("onChange", time);
    },
  },
  mounted() {
    if (this.defaultValue) {
      const today = moment().format("YYYY-MM-DD");
      this.time = moment(`${today} ${this.defaultValue}`)
        .startOf("minute")
        .format("HH:mm");
    }
  },
};
</script>

<style scoped></style>
